<template>
    <div class="content">
        <img src="../assets/img/back.png" @click="back" alt="">
        <div class="title">恭喜您获得!</div>
        <div class="goods_box">
            <div class="goods">
                <van-swipe class="my-swipe" :autoplay="3000" :show-indicators="false" @change="onChange">
                    <van-swipe-item v-for="(item,index) of goods_box" :key="index">
                        <img class="goods_image" :src="item.prize_icon" alt="">
                    </van-swipe-item>
                    <template #indicator="{ active, total }">
                        <div class="custom-indicator">{{ active + 1 }}/{{ total }}</div>
                    </template>
                </van-swipe>
                
            </div>
            <div v-if="goods_box && goods_box.length > 0" class="goods_name onelist-hidden">{{ goods_box[activeIndex].prize_name }}</div>
            <div v-if="goods_box && goods_box.length > 0" class="goods_type">{{ goods_box[activeIndex].prize_level }}</div>
            <div class="submit" @click="submit(1)">
                <div>再抽一次</div>
                <img src="../assets/img/jinbi.png" alt="">
                <div class="submit_bg">
                    <div class="price">￥39.9</div>
                    <div class="newPrice">￥39.9</div>
                </div>
            </div>
            <div class="submit5" @click="submit(5)">
                <div>再抽五次</div>
                <img src="../assets/img/jinbi.png" alt="">
                <div class="submit_bg" style="width:2.8rem;height: 0.76rem;">
                    <div class="price">￥199.5</div>
                    <div class="newPrice">￥199.5</div>
                </div>
            </div>
            <div class="text">前往APP-我的-我的仓库-选择-提货</div>
            <div class="downLoad">
                <div class="downLoad_a" @click="dowond_a">
                    <img src="../assets/img/Android_d.png" alt="">
                    <div>Android下载</div>
                </div>
                <div class="downLoad_i" @click="dowond_i">
                    <img src="../assets/img/iPhone_d.png" alt="">
                    <div>iPhone下载</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const throttle=(func, delay) => {
    // 缓存一个定时器
    let timer = null
    // 这里返回的函数是每次用户实际调用的节流函数 
    return function(...args) {
        if (!timer) { //判断timer是否有值,如果没有则说明定时器不存在即可继续执行
            timer = setTimeout(() => { //关
                func.apply(this, arguments)
                timer = null; //开
            }, delay)
        }
    }
}
import axios from 'axios'
    export default {
        data() {
            return {
                goods_box: [],
                data: {},
                user_id: '',
                order_id: '',
                goods_id: '',
                phone: '',
                activeIndex: 0
            }
        },
        created() {
            const { user_id, order_id, goods_id, phone} = this.$route.query
            this.user_id = user_id
            this.order_id = order_id
            this.goods_id = goods_id
            this.phone = phone
            if(this.user_id) {
                // this.toDowond()
                this.getData({
                    user_id:this.user_id,
                    order_id: this.order_id
                })
            }
        },
        methods: {
            onChange(e) {
                this.activeIndex = e
            },
            back() {
                this.$router.push('/')
            },
            dowond_a() {
                window.open('https://oss.aliyun.zhijiezuji.com/apk/lxmh.apk ')
            },
            dowond_i() {
                window.open('https://apps.apple.com/cn/app/id1604779545')

            }, 
            getData(data) {
                axios.post('/api/v1/box/payOrderSuNotLogin',data).then(res => {
                    if(res.data.code == 0) {
                        this.goods_box = res.data.data.list[0].prize
                    }
                })
            },
            submit(num) {
                axios.post(`${window.location.origin}/api/v1/tui/orderSubmit`, {
                    pay_price: 39.9 * num,
                    phone: this.phone,
                    pay_type: 2,
                    pay_mode: num == 1?1:3,
                    goods_id: this.goods_id,
                    goods_name: '手机',
                    return_url: window.location.origin + '/#/success'
                }).then(function (res) {
                    if(res.data.code == 0) {
                        window.location.href = res.data.data.pay_data
                    }else {
                        alert('下单失败')
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            toDowond:throttle(function()  {
                let data = JSON.parse(localStorage.getItem('adv_m_id'))
                let adv_m_id = this.get_object_first_attribute(data,1)
                axios.post('/api/v1/tuia/subTam', {
                    adv_m_id,
                    order_id: this.order_id,
                    adv_id: localStorage.getItem('adv'),
                    plid: localStorage.getItem('plid')
                })
            },800),
            get_object_first_attribute(data,val){
                for (var key in data) {
                    if(val) {
                        return data[key];
                    }else {
                        return key
                    }
                }
            }
        }
    }
</script>

<style lang="less" scoped>
.content{
    width:100%;
    height: 100vh;
    box-sizing: border-box;
    // background-image: url('../assets/img/success_bg.png');
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 0.4rem;
    .title{
        font-size: 0.72rem;
        font-weight: bold;
        color: #FFFFFF;
        text-align: center;
        background: linear-gradient(0deg, #4E35DB 0%, #9D4BF1 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .goods_box{
        margin-top: 0.4rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        .goods{
            width: 5.71rem;
            height: 5.71rem;
            // background-image: url('../assets/img/goods_bg.png');
            background-size: 100%;
            background-repeat: no-repeat;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 0.8rem;
            .my-swipe{
                width: 3.2rem;
                height: 2.03rem;
                position: relative;
                .custom-indicator {
                    position: absolute;
                    right: 5px;
                    bottom: 5px;
                    padding: 2px 5px;
                    font-size: 12px;
                    background: rgba(0, 0, 0, 0.1);
                    color: #1D0E45;
                }
            }
            .goods_image{
                width: 3.2rem;
                height: 2.03rem;
            }
        }
        .goods_name{
            width: 100%;
            margin-top: 0.59rem;
            font-size: 0.48rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
        }
        .goods_type{
            margin-top: 0.27rem;
            width: 2.13rem;
            height: 0.75rem;
            background: linear-gradient(0deg, #7041DF 0%, #FEDCDA 70%, #BE84EA 100%);
            border-radius: 0.37rem;
            text-align: center;
            line-height: 0.75rem;
            font-size: 0.33rem;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #1D0E45;
        }
        .submit{
            margin-top: 1.15rem;
            width: 7.47rem;
            height: 1.17rem;
            background: linear-gradient(90deg, #3CB6FF 0%, #5DD2FE 100%);
            border-radius: 0.59rem;
            display: flex;
            align-items: center;
            padding: 0.37rem 0.95rem 0.35rem 1.2rem;
            box-sizing: border-box;
            font-size: 0.48rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
        }
        .submit5{
            margin-top: 0.48rem;
            width: 7.47rem;
            height: 1.17rem;
            background: linear-gradient(0deg, #EA60F2 0%, #E74FD5 100%);
            border-radius: 0.59rem;
            display: flex;
            align-items: center;
            padding: 0.37rem 0.6rem 0.35rem .9rem;
            box-sizing: border-box;
            font-size: 0.48rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
        }
        .submit_bg{
                width: 2.4rem;
                height: 0.66rem;
                background-image: url('../assets/img/kuang.png');
                background-size: 100%;
                background-repeat: no-repeat;
                display: flex;
                align-items: center;
                padding: 0 0.15rem 0 0.27rem;
                box-sizing: border-box;
                .price{
                    font-size: 0.27rem;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    text-decoration: line-through;
                    color: #3E3E97;
                }
                .newPrice{
                    font-size: 0.35rem;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    color: #3E3E97;
                }
            }
        .text{
            margin-top: 0.89rem;
            font-size: 0.37rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #000028;
        }
        .downLoad{
            margin-top: 0.83rem;
            margin-bottom: 0.83rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            >div{
                width: 4.53rem;
                height: 1.17rem;
                border: 1px solid #1B1B1B;
                border-radius: 0.59rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0.31rem 0.77rem;
                box-sizing: border-box;
                font-size: 0.37rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #022222;

            }
            .downLoad_a{
                margin-right: 0.27rem;
            }

        }
    }
}
</style>